import React, { Component } from "react";
import { requests } from "../../../services/request";
import { globalState } from "../../../services/globalstate";
import { Toast, Tabs, Select } from "@shopify/polaris";
import {
  selectoptionMultiEndpoint,
  fetchDetailsByMarketplace,
  firstTimeSelection,
} from "./function";

class AdminLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      selectedTab: 0,
      selectedMarketplace: firstTimeSelection(),
    };
  }
  validateForm() {
    return !(this.state.username === "" || this.state.password === "");
  }
  onSubmit = () => {
    const pattern = /[a-zA-Z0-9\+\_]{3,}@[a-zA-Z0-9]{3,}.[a-zA-Z0-9]{2,5}/;
    let sendData;
    if (!pattern.test(this.state.username)) {
      sendData = {
        username: this.state.username,
        password: this.state.password,
      };
    } else {
      sendData = {
        email: this.state.username,
        password: this.state.password,
      };
    }
    if (this.validateForm()) {
      let url = "user/login";
      if (this.state.selectedTab === 1) {
        url = "sub-user/login";
      }
      requests.postRequest(url, sendData).then((e) => {
        this.toggleToast(e.message, !e.success);
        if (e.success && e.data.token) {
          globalState.setLocalStorage("auth_token", e.data.token);
          if (this.state.selectedTab === 1) {
            globalState.setLocalStorage("sub_user_authenticated", "true");
            url = "sub-user/login";
          }
          globalState.setLocalStorage("user_authenticated", "true");
          globalState.setLocalStorage("username", this.state.username);
          setTimeout(() => this.props.history.push("/panel"), 1000);
        }
      });
    } else {
      this.toggleToast("Please Enter proper Credentials", true);
    }
  };

  toggleToast = (message, error = false) => {
    this.setState({
      showToast: !this.state.showToast,
      showToastError: error,
      message: message,
    });
  };

  render() {
    const { showToast } = this.state;
    const toastMarkup = showToast ? (
      <Toast
        content={this.state.message}
        error={this.state.showToastError}
        onDismiss={this.toggleToast}
      />
    ) : null;
    return (
      <div className="wrapper-auth fadeInDown-auth">
        {toastMarkup}
        <div id="formContent-auth">
          <div className="fadeIn-auth first">
            <img
              src="https://d3vlhkqyz4y38a.cloudfront.net/skin/frontend/cedcomnew/default/images/header/logo/ced-logo-web.svg"
              id="icon-auth"
              alt="User Icon"
            />
          </div>
          <div className="fadeIn-auth first">
            <Tabs
              selected={this.state.selectedTab}
              onSelect={this.handleTabChange}
              tabs={[
                {
                  id: "user",
                  content: "Admin Login",
                  accessibilityLabel: "Main User",
                  panelID: "user",
                },
                // {
                //     id: 'sub-user',
                //     content: 'Sub User',
                //     panelID: 'sub-user',
                // },
              ]}
            />
          </div>
          <div className="fadeIn-auth second">
            <Select
              options={selectoptionMultiEndpoint()}
              value={globalState.getLocalStorage("selectedMarketplace")}
              onChange={(e) => {
                globalState.setLocalStorage("selectedMarketplace", e);
                const data = fetchDetailsByMarketplace(e);
                globalState.setLocalStorage(
                  "selectedAPIEndpoint",
                  data.API_ENDPOINT
                );
                globalState.setLocalStorage("selectedBearer", data.Bearer);
                this.setState({ selectedMarketplace: e });
              }}
            ></Select>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.onSubmit();
            }}
          >
            <input
              type="text"
              id="login"
              className="fadeIn-auth second"
              value={this.state.username}
              onChange={(e) => {
                this.setState({ username: e.target.value });
              }}
              name="login"
              placeholder="login"
            />
            <input
              type="password"
              id="password"
              value={this.state.password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
              className="fadeIn-auth third"
              name="login"
              placeholder="password"
            />
            <input
              type="submit"
              className="fadeIn-auth fourth"
              value="Log In"
            />
          </form>
          <div id="formFooter-auth">
            <a
              onClick={(e) => {
                this.redirect("/auth/registration", e);
              }}
              className="underlineHover-auth"
              href="javascript:void(0)"
            >
              Registration
            </a>
          </div>
          <div id="formFooter-auth">
            <a
              onClick={(e) => {
                this.redirect("/auth/login", e);
              }}
              className="underlineHover-auth"
              href="javascript:void(0)"
            >
              User Login
            </a>
          </div>
          {/*<div id="formFooter-auth">*/}
          {/*<a*/}
          {/*onClick={(e) =>{*/}
          {/*this.redirect('/auth/reset', e);*/}
          {/*}}*/}
          {/*className="underlineHover-auth"*/}
          {/*href="javascript:void(0)">*/}
          {/*Forgot Password?*/}
          {/*</a>*/}
          {/*</div>*/}
        </div>
      </div>
    );
  }

  handleTabChange = (selectedTabIndex) => {
    this.setState({ selectedTab: selectedTabIndex });
  };

  redirect(url) {
    this.props.history.push(url);
    return null;
  }
}

export default AdminLogin;
