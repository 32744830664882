import React from "react";
import {
  Page,
  Card,
  DataTable,
  Label,
  Button,
  Toast,
  Spinner,
  SkeletonBodyText,
  ContextualSaveBar,
  ButtonGroup,
  FormLayout,
  Pagination,
  Select,
} from "@shopify/polaris";

import { DeleteMinor } from "@shopify/polaris-icons";

import { requests } from "../../../services/request";

class SubUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      deleteThisItem: "",
      loading: true,
      currentPg: 1,
      count: 10,
      totalCount: 0,
    };
    this.getAllSubUsers();
    this.toggleToast = this.toggleToast.bind(this);
  }

  getAllSubUsers = () => {
    this.setState({ loading: true });
    requests
      .getRequest(
        `sub-user/getSubUsers?count=${this.state.count}&activePage=${this.state.currentPg}`
      )
      .then((e) => {
        if (e.success) {
          if (e.data.count > 0) {
            this.setState({
              totalCount: e.data.count,
            });
            this.setState({ rows: this.modifyDataRows(e.data.rows) });
          } else {
            this.toggleToast("No Sub User Found.");
          }
          this.setState({ loading: false });
        } else {
          this.toggleToast(e.message, true);
        }
        this.setState({ loading: false });
      });
  };

  modifyDataRows(data) {
    let rows = [];
    data.forEach((e) => {
      rows.push([
        <Label>{e.username}</Label>,
        <Label>{e.email}</Label>,
        <Label>{e.created_at}</Label>,
        <ButtonGroup>
          <Button
            size="slim"
            primary
            onClick={() => {
              this.redirect("/panel/subuser/edit/" + e._id);
            }}
          >
            Edit
          </Button>
          <Button
            size="slim"
            primary
            onClick={() => {
              if (e.status === "inactive") {
                this.setState({
                  subUserStatus: "active",
                  subUserId: e._id,
                  ContextualStatusChangeBarShow: true,
                });
              } else {
                this.setState({
                  subUserStatus: "inactive",
                  subUserId: e._id,
                  ContextualStatusChangeBarShow: true,
                });
              }
              this.setState({ label: this.changeLabel(e.status) });
            }}
          >
            {this.changeLabel(e.status)}
          </Button>

          <Button
            icon={DeleteMinor}
            size="slim"
            destructive
            onClick={() => {
              this.setState({
                deleteThisItem: e.username,
                ContextualSaveBarShow: true,
              });
            }}
          />
        </ButtonGroup>,
      ]);
    });
    return rows;
  }

  blockSubUser = () => {
    let { subUserStatus } = this.state;
    let { subUserId } = this.state;

    let url = "webapi/rest/v1/subuser-status-change";

    let sendData = {
      userId: subUserId,
      status: subUserStatus,
    };

    requests.postRequest(url, sendData).then((e) => {
      if (e.success) {
        this.toggleToast(e.message);
      } else {
        this.toggleToast(e.message, true);
      }
      this.getAllSubUsers();
    });
    this.setState({
      ContextualStatusChangeBarShow: false,
      subUserStatus: subUserStatus,
    });
  };

  changeLabel(status) {
    if (status === "active") {
      this.setState({ label: "block" });
      return "Block";
    }
    this.setState({ label: "unblock" });
    return "Unblock";
  }

  handleSave = () => {
    let { deleteThisItem } = this.state;
    requests
      .getRequest("sub-user/delete?username=" + deleteThisItem)
      .then((e) => {
        if (e.success) {
          this.toggleToast(e.message);
        } else {
          this.toggleToast(e.message, true);
        }
        this.getAllSubUsers();
      });
    this.setState({ ContextualSaveBarShow: false, deleteThisItem: "" });
  };

  render() {
    const { ContextualSaveBarShow, loading } = this.state;
    const { ContextualStatusChangeBarShow } = this.state;
    let showContext = ContextualSaveBarShow ? (
      <ContextualSaveBar
        message="Are you sure want to delete?"
        saveAction={{
          content: "Delete",
          destructive: true,
          onAction: this.handleSave,
        }}
        discardAction={{
          content: "Cancel",
          onAction: this.handleDiscard,
        }}
      />
    ) : ContextualStatusChangeBarShow ? (
      <ContextualSaveBar
        message={"Are you sure want to " + this.state.label + " this user?"}
        saveAction={{
          content: this.state.label,
          destructive: true,
          onAction: this.blockSubUser,
        }}
        discardAction={{ content: "Cancel", onAction: this.handleDiscard }}
      />
    ) : null;
    const { showToast } = this.state;
    const toastMarkup = showToast ? (
      <Toast
        content={this.state.message}
        error={this.state.showToastError}
        onDismiss={this.toggleToast}
      />
    ) : null;

    if (loading) {
      return (
        <Page title={"Sub USer"}>
          <Card sectioned>
            <Card>
              <DataTable
                columnContentTypes={["text", "text", "text", "text"]}
                headings={[
                  <Label>username</Label>,
                  <Label>email</Label>,
                  <Label>created at</Label>,
                  <Label>action</Label>,
                ]}
                rows={[
                  [
                    <Spinner />,
                    <SkeletonBodyText />,
                    <SkeletonBodyText />,
                    <SkeletonBodyText />,
                  ],
                  [
                    <Spinner />,
                    <SkeletonBodyText />,
                    <SkeletonBodyText />,
                    <SkeletonBodyText />,
                  ],
                ]}
              />
            </Card>
          </Card>
        </Page>
      );
    }
    //declaring options for selection of rows to display
    let optionspg = [
      { label: "10", value: "10" },
      { label: "20", value: "20" },
      { label: "50", value: "50" },
      { label: "100", value: "100" },
      { label: "200", value: "200" },
    ];
    const handleSelectChange = (value) => {
      this.setState(
        {
          count: value,
          currentPg: 1,
        },
        () => this.getAllSubUsers()
      );
    };
    return (
      <Page
        title={"Sub Users"}
        primaryAction={{
          content: "Create Sub User",
          onClick: () => {
            this.redirect("/panel/subuser/registration");
          },
        }}
      >
        {showContext}
        {toastMarkup}
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "text"]}
            headings={[
              <Label>username</Label>,
              <Label>email</Label>,
              <Label>created at</Label>,
              <Label>action</Label>,
            ]}
            rows={this.state.rows}
          />
        </Card>
        <Card>
          <Page>
            <FormLayout>
              <FormLayout.Group>
                <Pagination
                  label={`${this.state.currentPg}/${Math.ceil(
                    this.state.totalCount / this.state.count
                  )}`}
                  hasPrevious={this.state.currentPg === 1 ? false : true} //checking for pg value less than 1 an d disabeling previous btn if its value is 1
                  onPrevious={() => {
                    this.setState(
                      {
                        currentPg: this.state.currentPg - 1,
                      },
                      () => this.getAllSubUsers()
                    );
                  }}
                  hasNext={
                    this.state.currentPg ===
                    Math.ceil(this.state.totalCount / this.state.count)
                      ? false
                      : true
                  }
                  onNext={() => {
                    this.setState(
                      {
                        currentPg: this.state.currentPg + 1,
                      },
                      () => this.getAllSubUsers()
                    );
                  }}
                />
                <Select
                  label="Data per page: "
                  labelInline
                  options={optionspg}
                  onChange={handleSelectChange}
                  value={this.state.count}
                />
              </FormLayout.Group>
            </FormLayout>
          </Page>
        </Card>
      </Page>
    );
  }

  handleDiscard = () => {
    let { deleteThisItem } = this.state;
    this.setState({ ContextualSaveBarShow: false, deleteThisItem: "" });
    this.setState({ ContextualStatusChangeBarShow: false });
  };

  toggleToast(message, error = false) {
    this.setState({
      showToast: !this.state.showToast,
      showToastError: error,
      message: message,
    });
  }

  redirect(url) {
    this.props.history.push(url);
  }
}

export default SubUser;
