import React, { Component } from 'react';

import {
    Card,

} from "@shopify/polaris";

class About extends Component {
    render() {
        return (
            <Card sectioned>

            </Card>
        );
    }

    redirect(url) {
        this.props.history.push(url);
    }

}

export default About;