import React, { Component } from "react";
import {
  Card,
  TextField,
  Page,
  FormLayout,
  Stack,
  Button,
  DisplayText,
  ContextualSaveBar,
  Toast,
  Select,
  Label,
  Popover,
  ChoiceList,
  OptionList,
} from "@shopify/polaris";
import { requests } from "../../../../services/request";
import { parse } from "@fortawesome/fontawesome-svg-core";
// import { Option } from "@shopify/polaris/build/ts/latest/src/components/Listbox/components";

const defaultKeys = {
  key: "",
  value: "",
  error: false,
  errorText: "",
  disable: {
    key: false,
    value: false,
  },
};

const amazonArray = ["amazon", "amazonmcf"];

const marketPlacesArray = {
  shopify: {
    keys: [
      "app_key",
      "app_secret",
      "scope",
      "redirect_uri",
      "rest_ver",
      "graphQL_ver",
      "app_id",
      "group_code",
      "sales_channel",
      "sso_url",
      "app_url",
    ],
    canHaveMore: false,
  },
  cedcommerce: {
    keys: [
      "app_key",
      "app_secret",
      "scope",
      "redirect_uri",
      "rest_ver",
      "graphQL_ver",
      "app_id",
      "group_code",
      "sales_channel",
    ],
    canHaveMore: false,
  },
  shein: {
    keys: ["scope", "redirect_uri", "base_url", "group_code", "randomKey"],
  },
  google: {
    keys: [
      "client_id",
      "client_secret",
      "developer_token",
      "redirect_uri",
      "scope",
    ],
  },
  meta: {
    keys: [
      "app_id",
      "app_secret",
      "redirect_uri",
      "scope",
      "system_token",
      {
        api_version: [
          "v17.0",
          "v18.0",
          "v19.0",
          "v20.0",
          "v21.0",
          "v22.0",
          "v23.0",
          "v24.0",
          "v25.0",
          "v26.0",
          "v27.0",
          "v28.0",
          "v29.0",
          "v30.0",
        ],
      },
    ],
    canHaveMore: false,
  },
  lazada: {
    keys: ["app_key", "app_secret"],
    canHaveMore: false,
  },
  ebay: {
    keys: [
      "app_key",
      "app_secret",
      "dev_id",
      "runame",
      "scope",
      "redirect_url",
    ],
    canHaveMore: false,
  },
  etsy: {
    keys: ["consumer_key", "secret_key", "scope"],
    canHaveMore: false,
  },
  amazon: {
    keys: [
      "region",
      "dev_id",
      "access_key",
      "secret_key",
      "clientId",
      "clientSecret",
      "redirect_uri",
      "sp_oauth_url",
      "sp_application_id_sandbox",
      "sp_application_id_production",
      "role_aws_access_key",
      "role_aws_secret_key",
      "role_arn",
    ],
    canHaveMore: false,
  },
  hubspot: {
    keys: [
      "hapi_key",
      "app_id",
      "api_version",
      "client_api",
      "secret_id",
      "redirect_uri",
      "base_url",
      "scopes",
      "optional_scopes",
    ],
    canHaveMore: false,
  },
  zalando: {
    keys: ["client_secret", "client_id"],
    canHaveMore: false,
  },
  mercado_cbt: {
    keys: ["app_id", "app_secret", "redirect_uri"],
    canHaveMore: false,
  },
  vidaxl: {
    keys: ["default"],
    canHaveMore: false,
  },
  bigcommerce: {
    keys: [
      "app_key",
      "app_secret",
      "scope",
      "redirect_uri",
      "app_id",
      "group_code",
      "sales_channel",
      "base_url",
    ],
    canHaveMore: false,
  },
  onyx: {
    keys: [
      "client_id",
      "client_secret",
      "scope",
      "redirect_uri",
      "group_code",
      // "app_key",
      // "app_secret",
      // 'app_id',
    ],
  },
  plivo: {
    keys: ["auth_id", "auth_token", "mobile_No"],
  },
  arise: {
    keys: ["app_key", "app_secret", "base_url", "redirect_uri"],
    canHaveMore: false,
  },
  magento: {
    keys: ["redirect_uri"],
    canHaveMore: false,
  },
  woocommerce: {
    keys: ["redirect_uri"],
    canHaveMore: false,
  },
  prestashop: {
    keys: ["redirect_uri"],
    canHaveMore: false,
  },
  zoho: {
    keys: [
      "app_id",
      "app_key",
      "app_secret",
      "scope",
      "redirect_uri",
      "group_code",
      "base_url_in",
      "base_url_us",
      "base_url_eu",
      "base_url_jp",
      "base_url_au",
    ],
    canHaveMore: false,
  },
  walmart: {
    keys: ["redirect_uri", "base_url"],
    canHaveMore: false,
  },
  freshdesk: {
    keys: [
      "api_key",
      "freshdesk_domain",
      "freshsales_token",
      "freshsales_domain",
      "redirect_uri",
      "lost_stage_id",
      "new_stage_id",
      "secret_key",
    ],
    canHaveMore: false,
  },
  twitter: {
    keys: [
      "client_id",
      "client_secret",
      "scope",
      "redirect_uri",
      "response_type",
      "code_challenge",
      "code_challenge_method",
      "app_key",
      "app_secret",
      "access_token",
      "access_token_secret",
    ],
    canHaveMore: false,
  },
  etsy: {
    keys: [
      "consumer_key",
      "secret_key",
      "scope",
      "base_url",
      "app_callback_url",
      "remote_redirect_uri",
    ],
    canHaveMore: false,
  },
  joom: {
    keys: [
      "app_id",
      "app_key",
      "app_secret",
      "redirect_uri",
      "group_code",
      "base_url",
      "auth_url",
    ],
    canHaveMore: false,
  },
  chargebee: {
    keys: ["site_name", "api_key"],
    canHaveMore: false,
  },
  shopline: {
    keys: [
      "appKey",
      "appSecret",
      "scope",
      "redirectUri",
      "rest_ver",
      "graphQL_ver",
      "app_id",
      "group_code",
      "sales_channel",
    ],
    canHaveMore: false,
  },
  catch: {
    keys: ["redirect_uri", "base_uri"],
    canHaveMore: false,
  },
  mixpanel: {
    keys: ["client_id", "client_secret", "group_code", "project_token"],
    canHaveMore: true,
  },
  michael: {
    keys: [],
    canHaveMore: false,
  },
  amazonmcf: {
    keys: [
      "region",
      "dev_id",
      "access_key",
      "secret_key",
      "clientId",
      "clientSecret",
      "redirect_uri",
      "sp_oauth_url",
      "sp_application_id_sandbox",
      "sp_application_id_production",
      "role_aws_access_key",
      "role_aws_secret_key",
      "role_arn",
      "sso_url",
      "notifications_sqs_arn",
    ],
    canHaveMore: false,
  },
  mirakl_agent: {
    keys: [
      "client_id",
      "client_secret",
      "company_id",
      "base_url",
      "auth_url",
      "redirect_uri",
    ],
    canHaveMore: false,
  },
  agent: {
    keys: [
      "client_id",
      "client_secret",
      "company_id",
      "base_url",
      "auth_url",
      "redirect_uri",
    ],
    canHaveMore: false,
  },
  mirakl: {
    keys: [
      "client_id",
      "client_secret",
      "company_id",
      "base_url",
      "auth_url",
      "redirect_uri",
    ],
    canHaveMore: false,
  },
  manomano: {
    keys: ["redirect_uri", "base_uri"],
    canHaveMore: false,
  },
  magentonative: {
    keys: ["redirect_uri"],
    canHaveMore: false,
  },
  trendyol: {
    keys: ["firm_name", "storefront_code", "base_url"],
    canHaveMore: false,
  },
  aliexpress: {
    keys: ["base_url", "app_key", "app_secret", "redirect_uri"],
    canHaveMore: false,
  },
  tiktok: {
    keys: [
      "app_key",
      "app_secret",
      "redirect_uri",
      "auth_base_url",
      "base_url",
      "sso_url",
    ],
    canHaveMore: false,
  },
  tiktokads: {
    keys: ["app_id", "app_secret", "redirect_uri", "scope", "system_token"],
    canHaveMore: false,
  },
  woobilling: {
    keys: ["api_key", "api_secret", "base_uri"],
    canHaveMore: false,
  },
  wix: {
    keys: [
      "api_base_url",
      "installation_url",
      "redirect_url",
      "app_secret",
      "app_id",
      "public_key",
      "dashboard_url",
    ],
  },
};

const statusArray = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
  { label: "Under Development", value: "Under Development" },
];

// array for time unit array
const timeUnitArray = [
  { label: "Hours", value: "hours" },
  { label: "Minutes", value: "minutes" },
];

class AppRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Popover: false,
      webhookOptions: [],
      selectedWebhook: [],
      appGeneralDetails: [
        { key: "name", label: "App Name" },
        { key: "url", label: "App Location (URL)" },
        {
          key: "status",
          label: "App Status",
          value: "Under Development",
          type: "select",
          arrayOption: statusArray,
          optional: true,
        },
        {
          key: "additional_input",
          label: "Additional Data (Optional)",
          optional: true,
        },
        { key: "group_code", label: "Group Code", optional: true },
        { key: "app_code", label: "App Code", optional: true },
        { key: "marketPlaceHandler", label: "MarketPlace Handler" },
        {
          key: "erase_data_after_uninstall",
          type: "col",
          label: "Erase Data After Uninstall Time",
          unit: {
            key: "erase_data_after_unit",
            label: "Duration",
            value: "hours",
            arrayOption: timeUnitArray,
            optional: true,
          },
        },
      ].map((c) => ({ ...defaultKeys, ...c })),
      enteredAppCredentials: [JSON.parse(JSON.stringify(defaultKeys))],
      enteredSandboxCredentials: [JSON.parse(JSON.stringify(defaultKeys))],
      appID: props.match.params.id ? props.match.params.id : null,
      ContextualSaveBarShow: false,
      marketPlaceValue: "shopify",
      canHaveMore: true,
      enteredAppCredentialsAmazon: {
        EU: [],
        NA: [],
        FE: [],
      },
    };
    this.onAdd = this.onAdd.bind(this);
    this.onSub = this.onSub.bind(this);
    this.toggleToast = this.toggleToast.bind(this);
    this.handleMarketPlaceChange = this.handleMarketPlaceChange.bind(this);
  }

  getByID() {
    if (this.state.appID !== null) {
      requests
        .getRequest("webapi/rest/v1/apps?id=" + this.state.appID)
        .then((e) => {
          if (e.success && e.data !== null) {
            this.convertDataToDefaultFormat(e.data);
          } else {
            if (e.message) this.toggleToast(e.message, true);
            else this.toggleToast("No App Found With given Key", true);
          }
          this.saveOldDetails();
        });
    } else {
      this.saveOldDetails();
    }
  }

  getWebhooks() {
    requests.getRequest("webapi/rest/v1/marketplaceswebhooks").then((e) => {
      this.setState({ webhookOptions: [] });
      if (e.success && e.Webhooks[this.state.marketPlaceValue]) {
        let options = [];
        e["Webhooks"][this.state.marketPlaceValue].forEach((element) => {
          options.push({
            label: element["topic"],
            value: element["code"],
            queue_name: element?.queue_name,
          });
        });
        // console.log(options)
        this.setState({
          webhookOptions: options,
        });
      } else {
        if (e.message) this.toggleToast(e.message, true);
        // else this.toggleToast("No App Found With given Key", true);
      }
    });
  }
  componentWillMount() {
    this.getWebhooks();
  }

  componentDidMount() {
    if (this.state.appID !== null) {
      this.getByID();
      this.getWebhooks();
    } else {
      this.saveOldDetails();
    }
    this.handleMarketPlaceChange(this.state.marketPlaceValue);
  }

  convertDataToDefaultFormat = (value) => {
    let { appGeneralDetails, enteredAppCredentialsAmazon } = this.state;
    let enteredAppCredentials = [];
    let enteredSandboxCredentials = [];
    delete value["write_connection_service"];
    delete value["_id"];

    let marketplace = value["marketplace"] ? value["marketplace"] : "";

    if (value["marketplace"] !== undefined) {
      enteredAppCredentials = this.handleMarketPlaceChange(
        value["marketplace"],
        value
      );
      if (value?.webhooks?.length > 0) {
        let { selectedWebhook } = this.state;
        value.webhooks.forEach((value) => {
          selectedWebhook.push(value["code"]);
        });
        this.setState({ selectedWebhook });
      }
      if (marketplace !== "amazon" && marketplace !== "amazonmcf")
        enteredSandboxCredentials = this.handleMarketPlaceChange(
          value["marketplace"]
        );
      delete value["marketplace"];
    }
    appGeneralDetails.forEach((e) => {
      // fetch time unit
      if (typeof e.unit !== "undefined") {
        e.unit.value = value[e.unit.key];
        delete value[e.unit.key];
      }
      if (value[e.key] !== undefined) {
        e.value = value[e.key];
        delete value[e.key];
      }
    });

    if (marketplace !== "amazon" && marketplace !== "amazonmcf") {
      if (value["live"] !== undefined && value["sandbox"] !== undefined) {
        Object.keys(value["live"]).map((e) => {
          enteredAppCredentials.forEach((appCr, index) => {
            let keyName = appCr.key;
            if (typeof keyName !== "string") {
              keyName = Object.keys(appCr.key)[0];
            }
            if (keyName === e) {
              enteredAppCredentials[index]["value"] = value["live"][e];
              enteredAppCredentials = JSON.parse(
                JSON.stringify(enteredAppCredentials)
              );
            }
          });
        });
        enteredSandboxCredentials = JSON.parse(
          JSON.stringify(enteredSandboxCredentials)
        );
        Object.keys(value["sandbox"]).map((e) => {
          enteredSandboxCredentials.forEach((appCr, index) => {
            let keyName = appCr.key;
            if (typeof keyName !== "string") {
              keyName = Object.keys(appCr.key)[0];
            }
            if (keyName === e) {
              enteredSandboxCredentials[index]["value"] = value["sandbox"][e];
              enteredSandboxCredentials = JSON.parse(
                JSON.stringify(enteredSandboxCredentials)
              );
            }
          });
        });
      } else {
        Object.keys(value).map((e) => {
          enteredAppCredentials.forEach((appCr, index) => {
            let keyName = appCr.key;
            if (typeof keyName !== "string") {
              keyName = Object.keys(appCr.key)[0];
            }
            if (keyName === e) {
              // if ( enteredAppCredentials[index] === undefined ) enteredAppCredentials.push(JSON.parse(JSON.stringify(defaultKeys)));
              enteredAppCredentials[index]["value"] = value[e];
              enteredAppCredentials = JSON.parse(
                JSON.stringify(enteredAppCredentials)
              );
              enteredSandboxCredentials[index]["value"] = value[e];
              enteredSandboxCredentials = JSON.parse(
                JSON.stringify(enteredSandboxCredentials)
              );
            }
          });
        });
      }
    }

    this.setState({
      appGeneralDetails: appGeneralDetails,
      enteredAppCredentials: enteredAppCredentials,
      enteredSandboxCredentials: enteredSandboxCredentials,
    });
  };

  fillAmazonDetails = (enteredAppCredentialsAmazon, value) => {
    if (Object.keys(value).length === 0) return true;

    Object.keys(enteredAppCredentialsAmazon).forEach((key) => {
      if (value[key] !== undefined) {
        Object.keys(value[key]).map((e) => {
          enteredAppCredentialsAmazon[key].forEach((appCr, index) => {
            let keyName = appCr.key;
            if (typeof keyName !== "string") {
              keyName = Object.keys(appCr.key)[0];
            }
            if (keyName === e) {
              // if ( enteredAppCredentialsAmazon[key][index] === undefined ) enteredAppCredentialsAmazon.push(JSON.parse(JSON.stringify(defaultKeys)));
              enteredAppCredentialsAmazon[key][index]["value"] = value[key][e];
            }
          });
        });
      }
    });
    // console.log(enteredAppCredentialsAmazon);
    this.setState({ enteredAppCredentialsAmazon: enteredAppCredentialsAmazon });
  };

  renderApp = () => {
    return (
      <React.Fragment>
        <div className="row mt-5 mb-5">
          <div className="col-12 col-sm-3 p-5">
            <DisplayText size="small">
              <b>App details</b>
            </DisplayText>
            <br />
            <p>Enter the your App credentials.</p>
          </div>
          <div className="col-12 col-sm-9">
            <Card sectioned>
              <FormLayout>
                {this.state.enteredAppCredentials.map((key, index) => {
                  return (
                    <Stack
                      wrap={false}
                      alignment="leading"
                      spacing="tight"
                      key={index}
                    >
                      <Stack.Item fill>
                        <Stack distribution="fill" spacing="tight">
                          {key.disable.key ? (
                            <Label id={"123"}>
                              {typeof key.key === "string"
                                ? key.key
                                : Object.keys(key.key)[0]}
                            </Label>
                          ) : (
                            <TextField
                              label="Key"
                              placeholder="Key"
                              labelHidden
                              disabled={key.disable.key}
                              error={
                                this.state.enteredAppCredentials[index][
                                  "errorText"
                                ]
                              }
                              value={
                                this.state.enteredAppCredentials[index]["key"]
                              }
                              onChange={(e) => {
                                this.handleOnChange(index, "key", e);
                              }}
                              readOnly={false}
                            />
                          )}
                          {typeof key.key === "string" ? (
                            <TextField
                              type="Link"
                              label="value"
                              disabled={key.disable.value}
                              placeholder="Value"
                              labelHidden
                              value={
                                this.state.enteredAppCredentials[index]["value"]
                              }
                              onChange={(e) => {
                                this.handleOnChange(index, "value", e);
                              }}
                              readOnly={false}
                            />
                          ) : (
                            <Select
                              options={[
                                { label: "select", value: "", disabled: true },
                                ...Object.values(key.key)?.[0]?.map(
                                  (element) => {
                                    return { label: element, value: element };
                                  }
                                ),
                              ]}
                              value={
                                this.state.enteredAppCredentials[index]["value"]
                              }
                              onChange={(e) => {
                                this.handleOnChange(index, "value", e);
                              }}
                            />
                          )}
                        </Stack>
                      </Stack.Item>
                      {index !== 0 ? (
                        <Button
                          destructive
                          disabled={this.state.canHaveMore}
                          icon="subtract"
                          onClick={this.onSub.bind(this, index)}
                        />
                      ) : (
                        <Button
                          disabled={this.state.canHaveMore}
                          primary
                          icon="add"
                          onClick={this.onAdd}
                        />
                      )}
                    </Stack>
                  );
                })}
              </FormLayout>
            </Card>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-12 col-sm-3 p-5">
            <DisplayText size="small">
              <b>Sandbox details</b>
            </DisplayText>
            <br />
            <p>Enter the your App Sandbox credentials.</p>
          </div>
          <div className="col-12 col-sm-9">
            <Card sectioned>
              <FormLayout>
                {this.state.enteredSandboxCredentials.map((key, index) => {
                  return (
                    <Stack
                      wrap={false}
                      alignment="leading"
                      spacing="tight"
                      key={index}
                    >
                      <Stack.Item fill>
                        <Stack distribution="fill" spacing="tight">
                          {key.disable.key ? (
                            <Label id={"123"}>
                              {typeof key.key === "string"
                                ? key.key
                                : Object.keys(key.key)[0]}
                            </Label>
                          ) : (
                            <TextField
                              label="Key"
                              placeholder="Key"
                              labelHidden
                              disabled={key.disable.key}
                              error={
                                this.state.enteredSandboxCredentials[index][
                                  "errorText"
                                ]
                              }
                              value={
                                this.state.enteredSandboxCredentials[index][
                                  "key"
                                ]
                              }
                              onChange={(e) => {
                                this.handleSandboxOnChange(index, "key", e);
                              }}
                              readOnly={false}
                            />
                          )}
                          {typeof key.key === "string" ? (
                            <TextField
                              type="Link"
                              label="value"
                              disabled={key.disable.value}
                              placeholder="Value"
                              labelHidden
                              value={
                                this.state.enteredSandboxCredentials[index][
                                  "value"
                                ]
                              }
                              onChange={(e) => {
                                this.handleSandboxOnChange(index, "value", e);
                              }}
                              readOnly={false}
                            />
                          ) : (
                            <Select
                              options={[
                                { label: "select", value: "", disabled: true },
                                ...Object.values(key.key)?.[0]?.map(
                                  (element) => {
                                    return { label: element, value: element };
                                  }
                                ),
                              ]}
                              value={
                                this.state.enteredSandboxCredentials[index][
                                  "value"
                                ]
                              }
                              onChange={(e) => {
                                this.handleSandboxOnChange(index, "value", e);
                              }}
                            />
                          )}
                        </Stack>
                      </Stack.Item>
                      {index !== 0 ? (
                        <Button
                          destructive
                          disabled={this.state.canHaveMore}
                          icon="subtract"
                          onClick={this.onSub.bind(this, index)}
                        />
                      ) : (
                        <Button
                          disabled={this.state.canHaveMore}
                          primary
                          icon="add"
                          onClick={this.onAdd}
                        />
                      )}
                    </Stack>
                  );
                })}
              </FormLayout>
            </Card>
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderAmazonApp = () => {
    let { enteredAppCredentialsAmazon } = this.state;

    return Object.keys(enteredAppCredentialsAmazon).map((AppCredKey) => {
      return (
        <React.Fragment key={AppCredKey}>
          <div className="row mt-5 mb-5">
            <div className="col-12 col-sm-3 p-5">
              <DisplayText size="small">
                <b>Amazon {AppCredKey} details</b>
              </DisplayText>
              <br />
              <p>Enter the your App credentials.</p>
            </div>
            <div className="col-12 col-sm-9">
              <Card sectioned>
                <FormLayout>
                  {enteredAppCredentialsAmazon[AppCredKey].map((key, index) => {
                    return (
                      <Stack
                        wrap={false}
                        alignment="leading"
                        spacing="tight"
                        key={index}
                      >
                        <Stack.Item fill>
                          <Stack distribution="fill" spacing="tight">
                            {key.disable.key ? (
                              <Label id={"123"}>{key.key}</Label>
                            ) : (
                              <TextField
                                label="Key"
                                placeholder="Key"
                                labelHidden
                                disabled={key.disable.key}
                                error={
                                  enteredAppCredentialsAmazon[AppCredKey][
                                    index
                                  ]["errorText"]
                                }
                                value={
                                  enteredAppCredentialsAmazon[AppCredKey][
                                    index
                                  ]["key"]
                                }
                                onChange={(e) => {
                                  this.handleAmazonOnChange(
                                    index,
                                    "key",
                                    e,
                                    AppCredKey
                                  );
                                }}
                                readOnly={false}
                              />
                            )}
                            <TextField
                              type="Link"
                              label="value"
                              disabled={key.disable.value}
                              placeholder="Value"
                              labelHidden
                              value={
                                enteredAppCredentialsAmazon[AppCredKey][index][
                                  "value"
                                ]
                              }
                              onChange={(e) => {
                                this.handleAmazonOnChange(
                                  index,
                                  "value",
                                  e,
                                  AppCredKey
                                );
                              }}
                              readOnly={false}
                            />
                          </Stack>
                        </Stack.Item>
                        {index !== 0 ? (
                          <Button
                            destructive
                            disabled={this.state.canHaveMore}
                            icon="subtract"
                            onClick={this.onSub.bind(this, index)}
                          />
                        ) : (
                          <Button
                            disabled={this.state.canHaveMore}
                            primary
                            icon="add"
                            onClick={this.onAdd}
                          />
                        )}
                      </Stack>
                    );
                  })}
                </FormLayout>
              </Card>
            </div>
          </div>
        </React.Fragment>
      );
    });
  };

  renderGeneralApp = () => {
    return (
      <div className="row mt-5 mb-5">
        <div className="col-12 col-sm-3 p-5">
          <DisplayText size="small">
            <b>General details</b>
          </DisplayText>
          <br />
          <p>Enter the default details for your App..</p>
        </div>
        <div className="col-12 col-sm-9">
          <Card sectioned>
            <FormLayout>
              {this.state.appGeneralDetails.map((key, index) => {
                // Dropdown to set time unit min/hour
                if (key.type === "col") {
                  return (
                    <FormLayout.Group key={index}>
                      <TextField
                        type="number"
                        error={key["errorText"]}
                        label={key["label"]}
                        labelHidden
                        placeholder={key["label"]}
                        value={key["value"]}
                        onChange={(e) => {
                          this.handleGeneralOnChange(index, "value", e);
                        }}
                        readOnly={false}
                      />
                      <Select
                        key={key["unit"].key}
                        label={key["unit"].label}
                        labelInline
                        options={key["unit"].arrayOption}
                        value={key["unit"].value}
                        onChange={(e) => {
                          this.handleTimeOnChange(index, "value", e);
                        }}
                        readOnly={false}
                      />
                    </FormLayout.Group>
                  );
                }
                if (key.type === "select") {
                  return (
                    <Select
                      key={index}
                      label={key.label}
                      labelInline
                      options={key.arrayOption}
                      value={key.value}
                      onChange={(e) => {
                        this.handleGeneralOnChange(index, "value", e);
                      }}
                      readOnly={false}
                    />
                  );
                }
                // console.log(key);
                return (
                  <FormLayout.Group key={index}>
                    <TextField
                      type="Link"
                      error={key["errorText"]}
                      label={key["label"]}
                      labelHidden
                      placeholder={key["label"]}
                      value={key["value"]}
                      onChange={(e) => {
                        this.handleGeneralOnChange(index, "value", e);
                      }}
                      readOnly={false}
                    />
                  </FormLayout.Group>
                );
              })}
              {this.state.webhookOptions.length > 0 && this.renderWebhook()}
            </FormLayout>
          </Card>
        </div>
      </div>
    );
  };

  renderMarketPlaceDropDown = () => {
    let key = [];
    Object.keys(marketPlacesArray).forEach((e) => {
      key.push({ label: e, value: e });
    });
    return (
      <div className="row">
        <div className="offset-8 offset-sm-8 col-4 col-sm-3">
          <Select
            label={"MarketPlace"}
            labelInline
            placeholder="Select"
            options={key}
            value={this.state.marketPlaceValue}
            onChange={(e) => {
              this.handleMarketPlaceChange(e);
            }}
            disabled={this.state.appID !== null}
          />
        </div>
      </div>
    );
  };
  renderWebhook = () => {
    const { webhookOptions, PopoverActive, selectedWebhook } = this.state;
    // const Popover = this.state.Popover

    return (
      <Popover
        active={PopoverActive}
        fullWidth={true}
        onClose={() => {
          this.setState({
            PopoverActive: !PopoverActive,
          });
        }}
        activator={
          <Button
            fullWidth
            disclosure
            onClick={() =>
              this.setState({
                PopoverActive: !PopoverActive,
              })
            }
          >
            Webhook
          </Button>
        }
      >
        <div style={{ padding: "40px" }}>
          <ChoiceList
            allowMultiple
            fullWidth={true}
            disclosure="down"
            choices={webhookOptions}
            selected={selectedWebhook}
            onChange={(value) => {
              this.setState({
                selectedWebhook: value,
                ContextualSaveBarShow: true,
              });
            }}
          />
        </div>
      </Popover>
    );
  };

  render() {
    const { ContextualSaveBarShow, marketPlaceValue } = this.state;

    let showContext = ContextualSaveBarShow ? (
      <ContextualSaveBar
        message="Unsaved changes"
        saveAction={{
          onAction: this.handleSave,
          content: "Save",
        }}
        discardAction={{
          onAction: this.handleDiscard,
          content: "Cancel",
        }}
      />
    ) : null;
    const { showToast } = this.state;
    const toastMarkup = showToast ? (
      <Toast
        content={this.state.message}
        error={this.state.showToastError}
        onDismiss={this.toggleToast}
      />
    ) : null;
    return (
      <Page
        title={this.state.appID ? "Edit App" : "Create App"}
        fullWidth
        primaryAction={{
          content: "Back",
          onClick: () => {
            this.redirect("/panel/app");
          },
        }}
      >
        {toastMarkup}
        {showContext}
        {this.renderMarketPlaceDropDown()}
        <hr />
        {this.renderGeneralApp()}
        <hr />
        {amazonArray.includes(marketPlaceValue)
          ? this.renderAmazonApp()
          : this.renderApp()}
        <hr />
      </Page>
    );
  }

  handleMarketPlaceChange(e, liveVal = {}) {
    let enteredAppCredentials = [JSON.parse(JSON.stringify(defaultKeys))];
    let canHaveMore = true;
    let marketPlaceValue = e;
    Object.keys(marketPlacesArray).forEach((keys) => {
      if (keys === e && marketPlacesArray[keys]["keys"]) {
        canHaveMore = !marketPlacesArray[keys]["canHaveMore"];
        marketPlacesArray[keys]["keys"].map((e, index) => {
          if (enteredAppCredentials[index] === undefined)
            enteredAppCredentials.push(JSON.parse(JSON.stringify(defaultKeys)));
          enteredAppCredentials[index]["key"] = e;
          enteredAppCredentials[index]["value"] = "";
          enteredAppCredentials[index]["disable"]["key"] = true;
          enteredAppCredentials = JSON.parse(
            JSON.stringify(enteredAppCredentials)
          );
        });
      }
    });

    if (amazonArray.includes(marketPlaceValue)) {
      let enteredAppCredentialsAmazon = {};
      Object.keys(this.state.enteredAppCredentialsAmazon).map((key) => {
        enteredAppCredentialsAmazon[key] = JSON.parse(
          JSON.stringify(enteredAppCredentials)
        );
      });
      this.setState(
        { enteredAppCredentialsAmazon: enteredAppCredentialsAmazon },
        () => {
          this.fillAmazonDetails(enteredAppCredentialsAmazon, liveVal);
        }
      );
    }

    this.setState(
      {
        marketPlaceValue: marketPlaceValue,
        enteredAppCredentials: enteredAppCredentials,
        enteredSandboxCredentials: JSON.parse(
          JSON.stringify(enteredAppCredentials)
        ),
        canHaveMore: canHaveMore,
      },
      () => this.getWebhooks()
    );
    return JSON.parse(JSON.stringify(enteredAppCredentials));
  }

  saveOldDetails = () => {
    this.setState({ oldState: JSON.parse(JSON.stringify(this.state)) });
  };

  SaveAmazonData = () => {
    let sendData = {},
      flag = true;

    let {
      marketPlaceValue,
      enteredAppCredentialsAmazon,
      appGeneralDetails,
      selectedWebhook,
      webhookOptions,
    } = this.state;

    if (this.state.appID !== null) {
      sendData["id"] = parseInt(this.state.appID);
    }

    appGeneralDetails.forEach((e, index) => {
      if (e["value"] !== "" || e["optional"]) {
        sendData[e["key"]] = e["value"];
      } else {
        flag = false;
        this.toggleToast("Cannot Be Empty.", true);
        appGeneralDetails[index]["error"] = true;
        appGeneralDetails[index]["errorText"] = "Cannot Be Empty.";
      }
    });

    Object.keys(enteredAppCredentialsAmazon).forEach((keys) => {
      enteredAppCredentialsAmazon[keys].forEach((e, index) => {
        if (!sendData[keys]) sendData[keys] = {};

        if (
          sendData[keys][e["key"]] === undefined &&
          e["key"] !== "" &&
          e["value"] !== ""
        ) {
          sendData[keys][e["key"]] = e["value"];
        } else {
          enteredAppCredentialsAmazon[keys][index]["error"] = true;
          if (e["key"] === "" || e["value"] === "") {
            enteredAppCredentialsAmazon[keys][index]["errorText"] =
              "Empty Key or Value Will Be ignored";
          } else {
            this.toggleToast(
              "Duplicate Key found , same as general setting.",
              true
            );
            flag = false;
            enteredAppCredentialsAmazon[keys][index]["errorText"] =
              "Duplicate Key found , same as general setting.";
          }
        }
      });
    });

    sendData["marketplace"] = marketPlaceValue;

    if (selectedWebhook.length > 0) {
      let webhooks = [];
      selectedWebhook.forEach((value) => {
        webhookOptions.forEach((item) => {
          if (value === item["value"]) {
            webhooks.push({
              topic: item["label"],
              code: item["value"],
            });
          }
        });
      });
      sendData["webhooks"] = webhooks;
    } else {
      sendData["webhooks"] = [];
    }

    if (flag) {
      requests.putRequest("webapi/rest/v1/apps", sendData).then((e) => {
        if (e.success) {
          this.toggleToast(e.message);
          this.setState({ ContextualSaveBarShow: false });
          this.getByID();
        } else {
          this.toggleToast(e.message, true);
        }
      });
    }
  };

  handleSave = () => {
    let {
      enteredAppCredentials,
      appGeneralDetails,
      marketPlaceValue,
      selectedWebhook,
      webhookOptions,
      enteredSandboxCredentials,
    } = this.state;

    if (amazonArray.includes(marketPlaceValue)) {
      this.SaveAmazonData();
      return true;
    }

    let sendData = {};
    let flag = true;
    appGeneralDetails.forEach((e, index) => {
      if (e["value"] !== "" || e["optional"]) {
        if (typeof e["unit"] !== "undefined") {
          sendData[e["unit"].key] = e["unit"].value;
        }

        if (typeof e.type !== "undefined" && e.type === "col") {
          if (
            Number.isInteger(parseInt(e.value)) &&
            Number(e.value) % 1 !== 0
          ) {
            flag = false;
            this.toggleToast("Value Must Be Integer.", true);
            appGeneralDetails[index]["error"] = true;
            appGeneralDetails[index]["errorText"] = "Value Must Be Integer.";
          }

          if (parseInt(e.value) < 0) {
            flag = false;
            this.toggleToast("Value Cannot Be Negative.", true);
            appGeneralDetails[index]["error"] = true;
            appGeneralDetails[index]["errorText"] = "Value Cannot Be Negative.";
          }
          e.value = String(Math.floor(e.value));
        }
        sendData[e["key"]] = e["value"];
      } else {
        flag = false;
        this.toggleToast("Cannot Be Empty.", true);
        appGeneralDetails[index]["error"] = true;
        appGeneralDetails[index]["errorText"] = "Cannot Be Empty.";
      }
    });
    sendData["live"] = {};
    enteredAppCredentials.forEach((e, index) => {
      let keyName = e["key"];
      if (typeof keyName !== "string") {
        keyName = Object.keys(e["key"])[0];
      }
      if (
        sendData["live"][keyName] === undefined &&
        keyName !== "" &&
        e["value"] !== ""
      ) {
        sendData["live"][keyName] = e["value"];
      } else {
        enteredAppCredentials[index]["error"] = true;
        if (keyName === "" || e["value"] === "") {
          enteredAppCredentials[index]["errorText"] =
            "Empty Key or Value Will Be ignored";
        } else {
          this.toggleToast(
            "Duplicate Key found , same as general setting.",
            true
          );

          flag = false;
          enteredAppCredentials[index]["errorText"] =
            "Duplicate Key found , same as general setting.";
        }
      }
    });
    sendData["sandbox"] = {};
    enteredSandboxCredentials.forEach((e, index) => {
      let keyName = e["key"];
      if (typeof keyName !== "string") {
        keyName = Object.keys(e["key"])[0];
      }
      if (
        sendData["sandbox"][keyName] === undefined &&
        keyName !== "" &&
        e["value"] !== ""
      ) {
        sendData["sandbox"][keyName] = e["value"];
      } else {
        enteredSandboxCredentials[index]["error"] = true;
        if (keyName === "" || e["value"] === "") {
          enteredSandboxCredentials[index]["errorText"] =
            "Empty Key or Value Will Be ignored";
        } else {
          flag = false;
          this.toggleToast(
            "Duplicate Key found , same as general setting.",
            true
          );
          enteredSandboxCredentials[index]["errorText"] =
            "Duplicate Key found , same as general setting.";
        }
      }
    });
    if (flag) {
      if (this.state.appID !== null) {
        sendData["id"] = parseInt(this.state.appID);
      }
      sendData["marketplace"] = marketPlaceValue;

      if (selectedWebhook.length > 0) {
        let webhooks = [];
        selectedWebhook.forEach((value) => {
          webhookOptions.forEach((item) => {
            if (value === item["value"]) {
              const webhookData = {
                topic: item["label"],
                code: item["value"],
              };
              if (item["queue_name"]) {
                webhookData["queue_name"] = item["queue_name"];
              }
              webhooks.push(webhookData);
            }
          });
        });
        sendData["webhooks"] = webhooks;
      } else {
        sendData["webhooks"] = [];
      }
      requests.putRequest("webapi/rest/v1/apps", sendData).then((e) => {
        if (e.success) {
          this.toggleToast(e.message);
          this.setState({ ContextualSaveBarShow: false });
          this.getByID();
        } else {
          this.toggleToast(e.message, true);
        }
      });
    } else {
      // this.toggleToast('Error In Validation',true)
    }
    this.setState({
      enteredAppCredentials: enteredAppCredentials,
      enteredSandboxCredentials: enteredSandboxCredentials,
      appGeneralDetails: appGeneralDetails,
    });
  };

  handleOnChange = (index, key, value) => {
    let { enteredAppCredentials } = this.state;
    enteredAppCredentials[index][key] = value;
    enteredAppCredentials[index]["error"] = false;
    enteredAppCredentials[index]["errorText"] = "";
    this.setState({
      enteredAppCredentials: enteredAppCredentials,
      ContextualSaveBarShow: true,
    });
  };

  handleAmazonOnChange = (index, key, value, Marketplace) => {
    let { enteredAppCredentialsAmazon } = this.state;
    enteredAppCredentialsAmazon[Marketplace][index][key] = value;
    enteredAppCredentialsAmazon[Marketplace][index]["error"] = false;
    enteredAppCredentialsAmazon[Marketplace][index]["errorText"] = "";
    this.setState({
      enteredAppCredentialsAmazon: enteredAppCredentialsAmazon,
      ContextualSaveBarShow: true,
    });
  };
  handleSandboxOnChange = (index, key, value) => {
    let { enteredSandboxCredentials } = this.state;
    enteredSandboxCredentials[index][key] = value;
    enteredSandboxCredentials[index]["error"] = false;
    enteredSandboxCredentials[index]["errorText"] = "";
    this.setState({
      enteredSandboxCredentials: enteredSandboxCredentials,
      ContextualSaveBarShow: true,
    });
  };

  handleGeneralOnChange = (index, key, value) => {
    let { appGeneralDetails } = this.state;
    appGeneralDetails[index][key] = value;
    appGeneralDetails[index]["error"] = false;
    appGeneralDetails[index]["errorText"] = "";
    this.setState({
      appGeneralDetails: appGeneralDetails,
      ContextualSaveBarShow: true,
    });
  };

  // handle time unit change
  handleTimeOnChange = (index, key, value) => {
    let { appGeneralDetails } = this.state;
    appGeneralDetails[index]["unit"][key] = value;
    appGeneralDetails[index]["unit"]["error"] = false;
    appGeneralDetails[index]["unit"]["errorText"] = "";
    this.setState({
      appGeneralDetails: appGeneralDetails,
      ContextualSaveBarShow: true,
    });
  };

  onAdd() {
    let { enteredAppCredentials, enteredSandboxCredentials } = this.state;
    enteredAppCredentials.push(JSON.parse(JSON.stringify(defaultKeys)));
    enteredSandboxCredentials.push(JSON.parse(JSON.stringify(defaultKeys)));
    this.setState({
      enteredAppCredentials: enteredAppCredentials,
      enteredSandboxCredentials: enteredSandboxCredentials,
    });
  }

  onSub(index) {
    let { enteredAppCredentials, enteredSandboxCredentials } = this.state;
    enteredAppCredentials.splice(index, 1);
    enteredSandboxCredentials.splice(index, 1);
    this.setState({
      enteredAppCredentials: enteredAppCredentials,
      enteredSandboxCredentials: enteredSandboxCredentials,
    });
  }

  handleDiscard = () => {
    this.setState(JSON.parse(JSON.stringify(this.state.oldState)));
  };

  toggleToast(message, error = false) {
    this.setState({
      showToast: !this.state.showToast,
      showToastError: error,
      message: message,
    });
  }

  redirect(url) {
    this.props.history.push(url);
  }
}

export default AppRegistration;
