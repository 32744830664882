import React, {Component} from 'react';
import {
    Button,
    Card,
    Stack,
    FormLayout,
    ResourceList,
    Avatar,
    TextStyle,
    Page,
    Toast,
    Spinner,
    SkeletonBodyText, DataTable
} from "@shopify/polaris";
import {requests} from "../../../services/request";

import { DeleteMinor,LinkMinor } from "@shopify/polaris-icons";

class TokenManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows:[],
            count:10,
            activePage:1,
            selectedItems: [],
            loading : true
        };
        this.getTokens();
        this.redirect = this.redirect.bind(this);
        this.removeToken = this.removeToken.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.toggleToast = this.toggleToast.bind(this);
    }
    getTokens() {
        requests.getRequest('core/token/get').then(e => {
            if(e.success){
                let rows = [];
                for (let i = e.data.rows.length - 1; i >= 0; i--) {
                    let temp = {
                        id: e.data.rows[i]['token_id'],
                        token_id: e.data.rows[i]['token_id'],
                        title: e.data.rows[i]['title'],
                        domain: e.data.rows[i]['domain'],
                        token: e.data.rows[i]['token'],
                        exp: e.data.rows[i]['exp'],
                        type: e.data.rows[i]['type']
                    };
                    rows.push(temp);
                }
                this.setState({rows:rows});
            } else {
                this.toggleToast(e.message, true);
            }
            this.setState({loading: false});
        });
    }

    removeToken(id) {
        requests.getRequest('core/token/remove?token_id=' + id).then(e => {
            if(e.success){
                this.toggleToast('Successfully Deleted');
                this.getTokens();
            } else {
                this.toggleToast('Failed To Delete', true);
            }
        });
    }
    handleSelectionChange(selectedItems) {
        this.setState({ selectedItems });
    }

    toggleToast(message, error = false) {
        this.setState({
            showToast:!this.state.showToast,
            showToastError: error,
            message: message,
        });
    }
    render() {
        const {showToast, loading} = this.state;
        const toastMarkup = showToast ? (
            <Toast content={this.state.message} error={this.state.showToastError} onDismiss={this.toggleToast} />
        ) : null;

        if ( loading ) {
            return (
                <Page title="Token Manager" fullWidth primaryAction={{content:"Create New Token", onClick:() =>{this.redirect('/panel/token/create')}}}>
                    {toastMarkup}
                    <Card sectioned>
                        <Spinner/> <SkeletonBodyText/> <SkeletonBodyText/> <SkeletonBodyText/>
                    </Card>
                </Page>
            );
        }

        return (
            <Page title="Token Manager" fullWidth primaryAction={{content:"Create New Token", onClick:() =>{this.redirect('/panel/token/create')}}}>
                {toastMarkup}
                <Card>
                    <ResourceListExample
                        toggleToast={this.toggleToast}
                        removeToken={this.removeToken}
                        redirect={this.redirect}
                        rows={this.state.rows} />
                </Card>
            </Page>
        );
    }
    redirect(url) {
        this.props.history.push(url);
    }
}

class ResourceListExample extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItems: [],
            rows:this.props.rows?this.props.rows:[],
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if ( nextProps.rows !== undefined && nextProps.rows !== this.state.rows ) {
            this.setState({rows: nextProps.rows});
        }
    }
    handleSelectionChange = (selectedItems) => {
        this.setState({selectedItems});
    };
    renderItem = (item) => {
        let {token_id, title, domain, token, exp, type} = item;
        if ( type === 'refresh' ) {
            type = 'Refresh Token';
        } else {
            type = 'API Token';
        }
        const media = <Avatar customer size="medium" name={title} />;
        return (
            <ResourceList.Item
                id={token_id}
                media={media}
            >
                <FormLayout>
                    <FormLayout.Group condensed>
                        <h3>
                            <TextStyle variation="strong">{title}</TextStyle>
                        </h3>
                        <Stack>
                            <h4>
                                <TextStyle variation="strong">{type}</TextStyle>
                            </h4>
                        </Stack>
                        <Stack>
                            <Button size="slim"
                                    icon={LinkMinor} onClick={() => {
                                if ( token !== '' ) {
                                    var textField = document.createElement('textarea');
                                    textField.innerText = token;
                                    document.body.appendChild(textField);
                                    textField.select();
                                    document.execCommand('copy');
                                    textField.remove();
                                    this.props.toggleToast('Successfully Copied');
                                } else {
                                    this.props.toggleToast('Failed To Copy',true);
                                }
                            }}>
                                Copy Link
                            </Button>
                            <Button
                                size="slim"
                                icon={DeleteMinor}
                                onClick={() => {
                                    this.props.removeToken(token_id)}}
                                destructive>
                                Delete
                            </Button>
                        </Stack>
                    </FormLayout.Group>
                    <TextStyle variation="subdued">{exp}</TextStyle>
                    <div>{domain}</div>
                </FormLayout>
            </ResourceList.Item>
        );
    };
    render() {
        const resourceName = {
            singular: 'Token',
            plural: 'Token(s)',
        };
        const promotedBulkActions = [
            {
                content: 'Delete Token',
                onAction: () => {
                    this.state.selectedItems.forEach(e => {
                        this.props.removeToken(e);
                    });
                }
            },
            // {
            //     content: 'Edit Token',
            //     onAction: () => {
            //         let end = '';
            //         let string = '';
            //         this.state.selectedItems.map(e => {
            //             string = e + end;
            //             end = ',';
            //         });
            //         console.log('Todo: implement bulk edit', string)
            //     }
            // },
        ];
        // const bulkActions = [
        //     {
        //         content: 'Delete Token',
        //         onAction: () => {
        //             this.state.selectedItems.forEach(e => {
        //                 this.props.removeToken(e);
        //             });
        //         }
        //     },
        // ];
        return (
            <Card>
                <ResourceList
                    resourceName={resourceName}
                    items={this.state.rows}
                    renderItem={this.renderItem}
                    selectedItems={this.state.selectedItems}
                    onSelectionChange={this.handleSelectionChange}
                    promotedBulkActions={promotedBulkActions}
                    // bulkActions={bulkActions}
                />
            </Card>
        );
    }
}

export default TokenManager;