import { environment } from "../environment/environment";
import { globalState } from "./globalstate";

import { isUndefined } from "util";
const message = `Sorry, the request was unsuccessful. Please come back later.`;
export const requests = {
  getRequest: (endpoint, params, fullUrl, hideLoader) => {
    if (isUndefined(hideLoader) || !hideLoader) {
      window.showLoader = true;
    }
    let paramsString = "";
    if (!isUndefined(params)) {
      paramsString += "?";
      for (let i = 0; i < Object.keys(params).length; i++) {
        const end = i < Object.keys(params).length - 1 ? "&" : "";
        paramsString +=
          Object.keys(params)[i] +
          "=" +
          encodeURIComponent(params[Object.keys(params)[i]]) +
          end;
      }
    }
    if (isUndefined(fullUrl) || !fullUrl) {
      const baseUrl =
        globalState.getLocalStorage("selectedAPIEndpoint") ??
        environment.API_ENDPOINT;
      return fetch(baseUrl + endpoint + paramsString, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + globalState.getBearerToken(),
        },
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          // window.showReportIssue = true;
          return res.json();
        })
        .then((e) => {
          if (
            e.code === "token_expired" ||
            e.code === "invalid_token" ||
            e.code === "future_token" ||
            e.code === "token_decode_error"
          ) {
            globalState.removeAllLocalStorage();
          }
          return e;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    } else {
      return fetch(endpoint + paramsString, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + globalState.getBearerToken(),
        },
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((e) => {
          if (
            e.code === "token_expired" ||
            e.code === "invalid_token" ||
            e.code === "future_token" ||
            e.code === "token_decode_error"
          ) {
            globalState.removeAllLocalStorage();
          }
          return e;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    }
  },
  postRequest: (endpoint, data, fullUrl, hideLoader) => {
    if (isUndefined(hideLoader) || !hideLoader) {
      window.showLoader = true;
    }
    if (isUndefined(fullUrl) || !fullUrl) {
      const baseUrl =
        globalState.getLocalStorage("selectedAPIEndpoint") ??
        environment.API_ENDPOINT;
      return fetch(baseUrl + endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + globalState.getBearerToken(),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((e) => {
          if (
            e.code === "token_expired" ||
            e.code === "invalid_token" ||
            e.code === "future_token" ||
            e.code === "token_decode_error"
          ) {
            globalState.removeAllLocalStorage();
          }
          return e;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    } else {
      return fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + globalState.getBearerToken(),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((e) => {
          if (
            e.code === "token_expired" ||
            e.code === "invalid_token" ||
            e.code === "future_token" ||
            e.code === "token_decode_error"
          ) {
            globalState.removeAllLocalStorage();
          }
          return e;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    }
  },
  deleteRequest: (endpoint, data, fullUrl, hideLoader) => {
    if (isUndefined(hideLoader) || !hideLoader) {
      window.showLoader = true;
    }
    if (isUndefined(fullUrl) || !fullUrl) {
      const baseUrl =
        globalState.getLocalStorage("selectedAPIEndpoint") ??
        environment.API_ENDPOINT;
      return fetch(baseUrl + endpoint, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + globalState.getBearerToken(),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((e) => {
          if (
            e.code === "token_expired" ||
            e.code === "invalid_token" ||
            e.code === "future_token" ||
            e.code === "token_decode_error"
          ) {
            globalState.removeAllLocalStorage();
          }
          return e;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    } else {
      return fetch(endpoint, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + globalState.getBearerToken(),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((e) => {
          if (
            e.code === "token_expired" ||
            e.code === "invalid_token" ||
            e.code === "future_token" ||
            e.code === "token_decode_error"
          ) {
            globalState.removeAllLocalStorage();
          }
          return e;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    }
  },
  putRequest: (endpoint, data, fullUrl, hideLoader) => {
    if (isUndefined(hideLoader) || !hideLoader) {
      window.showLoader = true;
    }
    if (isUndefined(fullUrl) || !fullUrl) {
      const baseUrl =
        globalState.getLocalStorage("selectedAPIEndpoint") ??
        environment.API_ENDPOINT;
      return fetch(baseUrl + endpoint, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + globalState.getBearerToken(),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((e) => {
          if (
            e.code === "token_expired" ||
            e.code === "invalid_token" ||
            e.code === "future_token" ||
            e.code === "token_decode_error"
          ) {
            globalState.removeAllLocalStorage();
          }
          return e;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    } else {
      return fetch(endpoint, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + globalState.getBearerToken(),
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (isUndefined(hideLoader) || !hideLoader) {
            window.showLoader = false;
          }
          return res.json();
        })
        .then((e) => {
          if (
            e.code === "token_expired" ||
            e.code === "invalid_token" ||
            e.code === "future_token" ||
            e.code === "token_decode_error"
          ) {
            globalState.removeAllLocalStorage();
          }
          return e;
        })
        .catch((e) => {
          return { success: false, message: message, code: e };
        });
    }
  },
};
