import React, {Component} from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import Login from "./components/login";
import Registration from "./components/registeration";
import ResetPassword from "./components/resetPassword";
import './components/css/auth.css';
import VerificationToken from "./components/verificationToken";
import AdminLogin from "./components/AdminLogin";

class Auth extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route path="/auth/login" component={Login}/>
                    <Route path="/auth/adminLogin" component={AdminLogin}/>
                    <Route exact path="/auth/registration" component={Registration}/>
                    <Route exact path="/auth/registration/:appid/:marketplace" component={Registration}/>
                    <Route path="/auth/reset" component={ResetPassword}/>
                    <Route path="/auth/verify/:verificationToken" component={VerificationToken}/>
                    <Redirect path="**" to='/auth/login'/>
                </Switch>
            </div>
        );
    }
}

export default withRouter(Auth);