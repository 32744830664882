import * as _env from "../../../environment/environment";
import { globalState } from "../../../services/globalstate";

/**
 * fetch all the multi endpoints and set to dropdown
 * @returns option for all endpoints
 */
export const selectoptionMultiEndpoint = () => {
  return Object.keys(_env.environment.multi_API_ENDPOINT).map((e) => {
    return {
      value: e,
      label: _env.environment.multi_API_ENDPOINT[e]["name"],
    };
  });
};

export const fetchDetailsByMarketplace = (selectedMarketplace = "") => {
  return _env.environment.multi_API_ENDPOINT[selectedMarketplace];
};
export const setGlobalstate = (data) => {
  const current1 = fetchDetailsByMarketplace(data);
  globalState.setLocalStorage("selectedAPIEndpoint", current1.API_ENDPOINT);
  globalState.setLocalStorage("selectedBearer", current1.Bearer);
  globalState.setLocalStorage("selectedMarketplace", data);
};
export const firstTimeSelection = () => {
  const sel = globalState.getLocalStorage("selectedMarketplace");
  const ToBeSelected = Object.keys(_env.environment.multi_API_ENDPOINT)[0];

  sel ?? setGlobalstate(ToBeSelected);
  return ToBeSelected;
};
