import React from 'react';
import PropTypes from 'prop-types';
import { Toast, Navigation, Frame, Card, TopBar, Modal,
    TextField, FormLayout, Loading, AppProvider } from '@shopify/polaris';

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            navBarItems: props.navBarItems ? this.modifyNavBarItems(props.navBarItems) : [[]],
            showToast: false,
            isLoading: false,
            isDirty: false,
            userMenuOpen: false,
            showMobileNavigation: false,
            modalActive: false,
            storeName: props.storeName? props.storeName : 'user' ,
            username: props.username?props.username:'User',
            supportSubject: '',
            supportMessage: '',
        };
        this.modifyNavBarItems = this.modifyNavBarItems.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    modifyNavBarItems(items) {
        return items.map(event => {
            return  event.map(oldData => {
                if ( oldData.routeToGo !== undefined ) {
                    oldData.onClick = () => this.redirect(oldData.routeToGo);
                }
                return oldData;
            });
        });
    }

    componentDidMount() {
        // console.log(this.state.navBarItems);
    }


    render() {
        const {
            showToast,
            isLoading,
            userMenuOpen,
            showMobileNavigation,
            modalActive,
            storeName,
        } = this.state;

        const toastMarkup = showToast ? (
            <Toast
                onDismiss={this.toggleState('showToast')}
                content="Changes saved"
            />
        ) : null;

        const userMenuActions = [
            {
                items: [
                    {
                        content: 'Sign Out',
                        onAction:() => {this.redirect('/auth')}
                    }
                    ],
            },
        ];

        const navigationUserMenuMarkup = (
            <Navigation.UserMenu
                actions={userMenuActions}
                name={this.state.username}
                detail={storeName}
                avatarInitials="C"
            />
        );

        const userMenuMarkup = (
            <TopBar.UserMenu
                actions={userMenuActions}
                name={this.state.username}
                detail={storeName}
                initials="C"
                open={userMenuOpen}
                onToggle={this.toggleState('userMenuOpen')}
            />
        );

        const topBarMarkup = (
            <TopBar
                showNavigationToggle={true}
                userMenu={userMenuMarkup}
                onSearchResultsDismiss={this.handleSearchResultsDismiss}
                onNavigationToggle={this.toggleState('showMobileNavigation')}
            />
        );

        const navigationMarkup = (
            <Navigation location={this.props.location.pathname} userMenu={navigationUserMenuMarkup}>
                {this.state.navBarItems.map((topLevel, topLevelIndex) => {
                    return <Navigation.Section
                        key={topLevelIndex}
                        items={topLevel}
                    />
                })}
            </Navigation>
        );

        const loadingMarkup = isLoading ? <Loading /> : null;

        const modalMarkup = (
            <Modal
                open={modalActive}
                onClose={this.toggleState('modalActive')}
                title="Contact support"
                primaryAction={{
                    content: 'Send',
                    onAction: this.toggleState('modalActive'),
                }}
            >
                <Modal.Section>
                    <FormLayout>
                        <TextField
                            label="Subject"
                            value={this.state.supportSubject}
                            onChange={this.handleSubjectChange}
                        />
                        <TextField
                            label="Message"
                            value={this.state.supportMessage}
                            onChange={this.handleMessageChange}
                            multiline
                        />
                    </FormLayout>
                </Modal.Section>
            </Modal>
        );

        const theme = {
            colors: {
                topBar: {
                    background: '#357997',
                },
            },
            logo: {
                width: 124,
                topBarSource:
                    'https://d3vlhkqyz4y38a.cloudfront.net/skin/frontend/cedcomnew/default/images/header/logo/ced-logo-web.svg',
                contextualSaveBarSource:
                    'https://d3vlhkqyz4y38a.cloudfront.net/skin/frontend/cedcomnew/default/images/header/logo/ced-logo-web.svg',
                url: 'https://cedcommerce.com',
                accessibilityLabel: 'Cedcommerce',
            },
        };

        return (
            <div style={{height: '500px'}}>
                <AppProvider theme={theme}>
                    <Frame
                        topBar={topBarMarkup}
                        navigation={navigationMarkup}
                        showMobileNavigation={showMobileNavigation}
                        onNavigationDismiss={this.toggleState('showMobileNavigation')}
                    >
                        {loadingMarkup}
                        {toastMarkup}
                        {modalMarkup}
                        {this.props.children}
                    </Frame>
                </AppProvider>
            </div>
        );
    }

    toggleState = (key) => {
        return () => {
            this.setState((prevState) => ({[key]: !prevState[key]}));
        };
    };

    handleSubjectChange = (supportSubject) => {
        this.setState({supportSubject});
    };

    handleMessageChange = (supportMessage) => {
        this.setState({supportMessage});
    };

    redirect(url) {
        this.props.redirect(url);
    }
}

Header.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    navBarItems: PropTypes.array.isRequired,
    redirect: PropTypes.func.isRequired,
    username: PropTypes.string,
    storeName: PropTypes.string,
};

export default Header;