/* eslint-disable eqeqeq */
import { Page, Card, DataTable, Button } from '@shopify/polaris';
import React from 'react'
import './style.css'

export default function DataTableExample(props) {
  let headings = []; let typeCol = [];

  var rows = [];
  if (props.access != undefined) {
    let Object1 = props.tableColumns;
    let keys = Object.keys(Object1);
    let value = Object.values(Object1).map((elment) => elment.visible);
    const arr = props.access;
    const len = arr.length;
    const setHeading = (noOfColumns, j) => {
      headings[noOfColumns] = [<h2>{Object1[keys[j]].name}</h2>]
    }
    for (let i = 0; i < len; i++) {
      let noOfColumns = 0;
      let obj = arr[i];
      let arr2 = [];
      for (let j = 0; j < value.length; j++) {
        if (value[j] == true) {
          arr2[noOfColumns] = <span className="content">{obj[keys[j]]}</span>
          typeCol[noOfColumns] = [Object1[keys[j]].type]
          if (typeCol[noOfColumns] == 'button') {
            let ObjtoSend = {
              id: obj.id,
              code: obj.code,
              title: obj.title,
              description: obj.description,
              toFrom: Object1[keys[j]].name
            }
            console.log(ObjtoSend, "Obj to Display");
            // eslint-disable-next-line no-loop-func
            arr2[noOfColumns] = [<Button primary key={obj.id} onClick={() => { props.handleGroupClick(ObjtoSend) }}>{Object1[keys[j]].name}</Button>]
          }
          if (i === 0)
            setHeading(noOfColumns, j)
          noOfColumns++;
        }
      }
      rows[i] = arr2;
      obj = {};
    }
    if (len === 0) {
      let noOfColumns = 0;
      for (let j = 0; j < value.length; j++) {
        if (value[j] == true) {
          setHeading(noOfColumns, j)
          noOfColumns++;
        }
      }
    }
  }

  return (
    <div className="data_table">
        <DataTable headings-large
          columnContentTypes={typeCol}
          headings={headings}
          rows={rows}
      />
    </div>
  );
}

