const API_END_POINT = "https://staging-api-backend.bwpapps.com/";
const REACT_APP_BEARER =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiNjMzODIwMDEyMTgzZTI2NTJhMGM5YTY3Iiwicm9sZSI6ImFwcCIsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsInRva2VuX2lkIjoiNjMzODc1ZjYwOTMxYTNiZmM0MGUyYWIyIn0.XvteUwYbwevHfbGdbqLNfV-jy9iwaLP1V31qM7Bu-npEANFYQCHo1K1WKt6sG63ajOVcnAYIHWRb3WfxsbxqMCpGlY5WQqjL3vD47iQYtUnzL7T7Vwgeh0VFlYrdRftkaV7hQH5IqoJIx3xmriE4eVyjJwU1KQIWrRupCkz7zI4rkPhafpaNMroRGnps6mkisaXGVZoC7yJBdX35EZSHnSZhae42Ygggcn3Jr290vjA8ixa_rhGWiD5N7LNBuwBHefulVt4Nc-dQdklF1aTcGeVNtEumCXvYIV4IAW4Rt_ekglIPGbWB9SgRjk4TL4_1baa3XeiCPDINppnFblGjew";

export const environment = {
  production: true,
  isLive: false,
  API_ENDPOINT: API_END_POINT,
  AppName: "API Connector",
  multi_API_ENDPOINT: {
    default: {
      name: "Default",
      API_ENDPOINT: API_END_POINT,
      default: true,
      Bearer: REACT_APP_BEARER,
    },
  },
};
/* Bearer is App token for server */
