import React, {Component} from 'react';
import {Page, Checkbox,Card, TextField, FormLayout, DataTable, ContextualSaveBar, Toast, Scrollable,Button} from '@shopify/polaris';
import {requests} from "../../../../services/request";
import '../../css/panel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faCheckCircle, faMinus, faFolder, faFolderOpen, faFile, faExpand, faSquare, faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';

import CheckboxTree from 'react-checkbox-tree';
import {
  LockMinor,
  CircleTickOutlineMinor,
} from "@shopify/polaris-icons";

// const nodes = [{
//     value: 'mars',
//     label: 'Mars',
//     children: [
//         { value: 'phobos', label: 'Phobos' ,children: [
//                 { value: 'phobos2', label: 'Phobos2' },
//                 { value: 'deimos2', label: 'Deimo2s' },
//             ]},
//         { value: 'deimos', label: 'Deimos' },
//     ],
// }];

class CreateSubUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sub_username:'',
            sub_email:'',
            sub_password:'',
            auth_url:'',
            sub_domain:'',
            apps:[],
            apps_temp:[['','','','']],
            ContextualSaveBarShow: false,
            sub_user_id: props.match.params && props.match.params.id ? props.match.params.id: null,
            checked: [],
            expanded: [],
            nodes:[],
            nodesFiltered:[]
        };
        this.getApps = this.getApps.bind(this);
        this.getTableData = this.getTableData.bind(this);
        this.toggleToast = this.toggleToast.bind(this);

    }

    componentDidMount() {
        this.getACLGroup();
    }

    getACLGroup = () => {
        requests.getRequest('acl-group/getRoleResource?group_code=admin').then(e => {
            if ( e.success ) {
                let modules = {};
                let controller = {};
                let nodes = [];
                let i = 0;
                let expanded = [];
                // break all the module
                e.data.resources.forEach(mod => {
                    if ( typeof  modules[mod['module']] !== 'object' )
                        modules[mod['module']] = [];
                    modules[mod['module']].push(mod);
                });
                // break modules controller one by one
                Object.keys(modules).forEach(mod => {
                    modules[mod].forEach(con => {
                        if ( typeof controller[mod] !== 'object' )
                            controller[mod] = {};
                        if ( typeof controller[mod][con['controller']] !== 'object' )
                            controller[mod][con['controller']] = [];

                        controller[mod][con['controller']].push(con);
                    })
                });
                Object.keys(controller).forEach(mod => {
                    nodes[i] = {label:mod, value:mod};
                    expanded.push(mod);
                    let controllerNode = [];
                    let j = 0;
                    Object.keys(controller[mod]).forEach(con => {
                        expanded.push(con + '-' + mod);
                        controllerNode[j] = {label:con, value:con + '-' + mod};
                        let actionNode = [];
                        let k = 0;
                        controller[mod][con].forEach(leafAction => {
                            actionNode[k] = {label:leafAction.action, value:leafAction.id};
                            k++;
                        });
                        controllerNode[j]['children'] = actionNode;
                        j++;
                    });
                    nodes[i]['children'] = controllerNode;
                    i++;
                });
                this.setState((oldState) => {
                    oldState.nodes = nodes;
                    oldState.nodesFiltered = nodes;
                    oldState.expanded = expanded;
                    return oldState;
                },() => this.getSubUser(this.props.match.params));
            } else {
                this.getSubUser(this.props.match.params);
                this.toggleToast(e.message, true);
            }
        })
    };

    getSubUser(params) {
        let url = 'sub-user/getSubUsers';
        if ( params.id ) {
            url += '?id=' + params.id;
            requests.getRequest(url).then(e => {
                if ( e.success && typeof e.data === 'object' ) {
                    if ( e.data ) {
                        this.setState({
                            sub_username: e.data.username,
                            sub_email: e.data.email,
                            sub_password:'',
                            checked: Object.keys(e.data.resources).map(keys => (e.data.resources[keys]))
                        },() => this.getApps(e.data.apps));
                    }
                } else {
                    this.getApps();
                }
            });
        } else {
            this.getApps();
        }
    }

    getApps(res = false) {
        let url = 'webapi/rest/v1/apps';
        requests.getRequest(url).then(e => {
            if ( e.success ) {
                let apps = [];
                e.data.forEach((key) => {

                // hide custom apps from sub-user panel
                if(typeof key.is_visible === 'undefined' || key.is_visible) {
                    let flag = true;
                    if ( res ) {
                        Object.keys(res).map(resKey => {
                            if ( res[resKey].app_id === key['_id'] ) {
                                flag = false;

                                apps.push({
                                    checked: true,
                                    auth_url: res[resKey]['auth_url'],
                                    domain: res[resKey]['domain'],
                                    frontend_uri: res[resKey]['frontend_uri'],
                                    sub_app_id : res[resKey]['_id'],
                                    _id: key['_id'],
                                    sub_app_status:res[resKey]['sub_app_status'],
                                    data: key,
                                });
                            }
                        });

                    }
                    if ( flag )  {
                        apps.push({
                            checked: false,
                            auth_url:'',
                            domain:'',
                            frontend_uri:'',
                            _id: key['_id'],
                            sub_app_status:'',
                            data: key,
                        });
                    }
                }
                });
                this.setState((oldState) => {
                    oldState.apps = apps;
                    return oldState;
                },(newState) => this.saveOldDetails(newState));
            } else {
                this.toggleToast(e.message, true);
            }
        })
    }

    getTableData(data) {
        let row = [];
        data.forEach((key, index) => {
              row.push([
                <Checkbox
                  label={""}
                  checked={this.state.apps[index]["checked"]}
                  onChange={(e) => {
                    this.handleAppChange(e, index, "checked");
                  }}
                />,
                key.data.name,
                key.data.marketplace,
                <TextField
                  disabled={!this.state.apps[index]["checked"]}
                  label={""}
                  labelHidden={true}
                  placeholder={
                    this.state.apps[index]["checked"]
                      ? "Enter The Url"
                      : "First check the Checkbox to Add the App"
                  }
                  value={this.state.apps[index]["auth_url"]}
                  onChange={(e) => {
                    this.handleAppChange(e, index);
                  }}
                />,
                <TextField
                  disabled={!this.state.apps[index]["checked"]}
                  label={""}
                  labelHidden={true}
                  placeholder={
                    this.state.apps[index]["checked"]
                      ? "Enter The Domain"
                      : "First check the Checkbox to Add the App"
                  }
                  value={this.state.apps[index]["domain"]}
                  onChange={(e) => {
                    this.handleAppChange(e, index, "domain");
                  }}
                />,
                <TextField
                  disabled={!this.state.apps[index]["checked"]}
                  label={""}
                  labelHidden={true}
                  placeholder={
                    this.state.apps[index]["checked"]
                      ? "Enter The Frontend uri "
                      : "First check the Checkbox to Add the App"
                  }
                  value={this.state.apps[index]["frontend_uri"]}
                  onChange={(e) => {
                    this.handleAppChange(e, index, "frontend_uri");
                  }}
                />,
                <Button
                  icon={
                    typeof this.state.apps[index]["sub_app_status"] ===
                    "undefined"
                      ? CircleTickOutlineMinor
                      : this.state.apps[index]["sub_app_status"] === "active"
                      ? CircleTickOutlineMinor
                      : LockMinor
                  }
                  onClick={(e) => {
                    let status = "active";

                    if (
                      typeof this.state.apps[index]["sub_app_status"] !==
                      "undefined"
                    ) {
                      status =
                        this.state.apps[index]["sub_app_status"] === "active"
                          ? "inactive"
                          : "active";
                    }

                    this.handleAppChange(status, index, "sub_app_status");
                  }}
                  primary
                />,
              ]);
        });
        return row;
    }

    handleSave = () => {
        let { sub_username, sub_email, sub_password, apps, checked } = this.state;
        if ( sub_username !== '' && sub_email !== '' && sub_password !== '' ) {
            let new_apps = [];
            apps.forEach(e => {
                if ( e.checked && e.auth_url !== '' ) {
                    let temp = JSON.parse(JSON.stringify(e));
                    delete temp.checked;
                    temp['app_id'] = temp['_id'];
                    delete temp['_id'];
                    temp['app_name'] = temp['data']['name'];
                    temp['app_marketplace'] = temp['data']['marketplace'];
                    delete temp['data'];
                    new_apps.push(temp);
                }
            });
            let sendData = {
                username:sub_username,
                email:sub_email,
                password:sub_password,
                resources: checked,
                apps:new_apps
            };
            requests.putRequest('sub-user/create',sendData).then(e => {
                if ( e.success ) {
                    this.toggleToast(e.message,true);
                    setTimeout(() => {
                        this.redirect('/panel/subuser');
                    },3000);
                } else {
                    this.toggleToast(e.message,true)
                }
            })
        } else {
            this.toggleToast('Please Fill All The Fields', true);
        }
    };

    handleUpdate = () => {
        let { apps, sub_user_id, sub_password, checked } = this.state;
        let new_apps = [];
        apps.forEach(e => {
            if ( e.checked && e.auth_url !== '' ) {
                let temp = JSON.parse(JSON.stringify(e));
                delete temp.checked;
                temp['app_id'] = temp['_id'];
                delete temp['_id'];
                temp['app_name'] = temp['data']['name'];
                temp['app_marketplace'] = temp['data']['marketplace'];
                delete temp['data'];
                new_apps.push(temp);
            }
        });
        let sendData = {
            id: sub_user_id,
            apps:new_apps,
            resources: checked,
        };
        if ( sub_password !== '' ) {
            sendData['password'] = sub_password;
        }

   
        requests.postRequest('sub-user/update',sendData).then(e => {
            if ( e.success ) {
                this.toggleToast(e.message,true);
                setTimeout(() => {
                    this.redirect('/panel/subuser');
                },4000);
            } else {
                this.toggleToast(e.message,true)
            }
        })

    };

    handleChange = (key, value) => {
        this.setState({
            [key]:value,
            ContextualSaveBarShow:true
        });
    };

    handleAppChange = (value, index, key = 'auth_url') => {
        let { apps } = this.state;
        apps[index][key] = value;
        this.setState({apps:apps,ContextualSaveBarShow:true});
    };

    toggleToast(message, error = false) {
        this.setState({
            showToast:!this.state.showToast,
            showToastError: error,
            message: message,
        });
    }

    render() {
        const { ContextualSaveBarShow, apps, sub_user_id, nodesFiltered } = this.state;
        let showContext = ContextualSaveBarShow ? <ContextualSaveBar
            message="Unsaved changes"
            saveAction={{
                content:sub_user_id === null ? "Save" : "Update",
                onAction: sub_user_id === null ? this.handleSave : this.handleUpdate,
            }}
            discardAction={{
                onAction: this.handleDiscard,
                content:'Cancel'
            }}/> : null;
        const {showToast} = this.state;
        const toastMarkup = showToast ? (
            <Toast content={this.state.message} error={this.state.showToastError} onDismiss={this.toggleToast} />
        ) : null;
        return (
            <Page title={sub_user_id === null ? "Create User" : "Edit User"} primaryAction={{content:"Back", onClick:()=>{this.redirect('/panel/subuser')}}}>
                {showContext}
                {toastMarkup}
                <Card sectioned>
                    <FormLayout>
                        <TextField
                            label={"Username"}
                            value={this.state.sub_username}
                            disabled={sub_user_id != null}
                            onChange={(e) => {this.handleChange('sub_username', e);}}
                            readOnly={false}/>
                        <TextField
                            label={"Email"}
                            value={this.state.sub_email}
                            disabled={sub_user_id != null}
                            type="email"
                            onChange={(e) => {this.handleChange('sub_email',e);}}
                            readOnly={false}/>
                        <TextField
                            label={sub_user_id != null ? "Enter New Password" : "Password"}
                            value={this.state.sub_password}
                            type="password"
                            disabled={sub_user_id != null}
                            onChange={(e) => {this.handleChange('sub_password',e);}}
                            readOnly={false}/>
                        <DataTable
                            columnContentTypes={['text','text','text','text',]}
                            headings={['Add', 'App Name', 'Marketplace', 'Auth Url','domain','Frontend Uri','Status']}
                            rows={this.getTableData(apps)}
                        />
                        <Card sectioned title="ACL Group">
                            <TextField
                                label={"Search"}
                                labelHidden
                                placeholder="Search"
                                value={this.state.filterText}
                                onChange={this.onFilterChange}
                                readOnly={false}/>
                            <br/>
                            <Scrollable style={{height:"300px"}}>
                                <CheckboxTree
                                    showExpandAll={true}
                                    nodes={nodesFiltered}
                                    checked={this.state.checked}
                                    expanded={this.state.expanded}
                                    onCheck={checked => {
                                        this.setState({ checked, ContextualSaveBarShow:true })
                                    }}
                                    onExpand={expanded => {
                                        this.setState({ expanded })
                                    }}
                                    icons={{
                                        check:<FontAwesomeIcon icon={faCheckCircle} color={'#0d47a1'}/>,
                                        uncheck: <FontAwesomeIcon icon={faSquare} color={"#c5cae9"}/>,
                                        halfCheck: <FontAwesomeIcon icon={faMinusCircle} color={"#311b92"}/>,
                                        expandClose: <FontAwesomeIcon icon={faExpand}/>,
                                        expandOpen: <FontAwesomeIcon icon={faCompressArrowsAlt}/>,
                                        expandAll: <FontAwesomeIcon icon={faExpand}/>,
                                        collapseAll: <FontAwesomeIcon icon={faCompressArrowsAlt}/>,
                                        parentClose: <FontAwesomeIcon icon={faFolder} color={"#000"}/>,
                                        parentOpen: <FontAwesomeIcon icon={faFolderOpen} color={"#000"}/>,
                                        leaf: <FontAwesomeIcon icon={faFile} color={"#000"}/>
                                    }}
                                />
                            </Scrollable>
                        </Card>
                    </FormLayout>
                </Card>
            </Page>
        );
    }

    onFilterChange = (e) =>  {
        this.setState({ filterText: e}, this.filterTree);
    }

    filterTree = () => {
        // Reset nodes back to unfiltered state
        if (!this.state.filterText) {
            this.setState(prevState => ({
                nodesFiltered: prevState.nodes,
            }));

            return;
        }

        const nodesFiltered = prevState => (
            { nodesFiltered: prevState.nodes.reduce(this.filterNodes, []) }
        );

        this.setState(nodesFiltered);
    }

    filterNodes = (filtered, node) => {
        const { filterText } = this.state;
        const children = (node.children || []).reduce(this.filterNodes, []);

        if (
            // Node's label matches the search string
            node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1 ||
            // Or a children has a matching node
            children.length
        ) {
            filtered.push({ ...node, children });
        }

        return filtered;
    };

    saveOldDetails = (oldState = false) => {
        if ( oldState ) {
            this.setState({oldState: JSON.parse(JSON.stringify(oldState))});
        }else {
            this.setState({oldState: JSON.parse(JSON.stringify(this.state))});
        }
    };

    handleDiscard = () => {
        this.setState(JSON.parse(JSON.stringify(this.state.oldState)));
    };

    redirect(url) {
        this.props.history.push(url);
    }
}

export default CreateSubUser;