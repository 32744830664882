import React, {Component} from 'react';
import {Toast} from "@shopify/polaris";
import {requests} from "../../../services/request";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:'',
            email:'',
        };
    }

    onSubmit = () => {
        requests.getRequest('core/user/forgot').then(e => {
            this.toggleToast(e.message, !e.success);
        });
    };

    toggleToast = (message, error = false) => {
        this.setState({
            showToast:!this.state.showToast,
            showToastError: error,
            message: message,
        });
    };

    render() {
        const {showToast} = this.state;
        const toastMarkup = showToast ? (
            <Toast content={this.state.message} error={this.state.showToastError} onDismiss={this.toggleToast} />
        ) : null;
        return (
            <div className="wrapper-auth fadeInDown-auth">
                {toastMarkup}
                <div id="formContent-auth">
                    {/*Tabs Titles*/}
                    {/*Icon*/}
                    <div className="fadeIn-auth first">
                        <img src="https://d3vlhkqyz4y38a.cloudfront.net/skin/frontend/cedcomnew/default/images/header/logo/ced-logo-web.svg" id="icon" alt="User Icon"/>
                    </div>
                    {/*Login Form */}
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        this.onSubmit();
                    }}>
                        <input
                            type="text"
                            id="login"
                            className="fadeIn-auth second"
                            value={this.state.username}
                            onChange={(e) => {this.setState({username:e.target.value})}}
                            name="login"
                            placeholder="login"/>
                        <input
                            type="text"
                            id="password"
                            value={this.state.email}
                            onChange={(e) => {this.setState({email:e.target.value})}}
                            className="fadeIn-auth third"
                            name="email"
                            placeholder="Email"/>
                        <input
                            type="submit"
                            className="fadeIn-auth fourth"
                            value="Reset Password"/>
                    </form>
                    {/*Remind Passowrd*/}
                    <div id="formFooter-auth">
                        <a
                            onClick={(e) =>{
                                this.props.history.push('/auth/login');
                            }}
                            className="underlineHover-auth"
                            href="javascript:void(0)">
                            Login
                        </a>
                    </div>
                    <div id="formFooter-auth">
                        <a
                            onClick={(e) =>{
                                this.props.history.push('/auth/registration');
                            }}
                            className="underlineHover-auth"
                            href="javascript:void(0)">
                            Registration
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResetPassword;