import React, { Component } from 'react';
import { Button, Card, Stack, FormLayout, Select, TextField, Page, Toast } from "@shopify/polaris";
import { requests } from "../../../../services/request";
import { globalState } from "../../../../services/globalstate";


class CreateEditToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: Object.keys(props.match.params).length === 0 ? 'Create Token' : 'Edit Token',
            apps: [],
            tokenCredentials: {
                title: '',
                domain: '',
                duration: '',
                type: 'customer_api',
                app_id: ''
            }
        };
        this.getAllTheApps();
        this.onSubmit = this.onSubmit.bind(this);
        this.toggleToast = this.toggleToast.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }


    getAllTheApps = () => {
        requests.getRequest('webapi/rest/v1/apps').then(e => {
            if (e.success && typeof e.data === 'object') {
                let apps = [];
                Object.keys(e.data).forEach(keys => {
                    apps.push({ label: e.data[keys]['name'], value: e.data[keys]['_id'].toString() })
                });
                this.setState((oldState) => {
                    oldState.apps = apps;
                    oldState.ContextualSaveBarShow = false;
                    return oldState;
                });
            } else {
                this.toggleToast(e.message, true);
            }
        })
    };

    handleChange = (key, value) => {
        let { tokenCredentials } = this.state;
        tokenCredentials[key] = value;
        this.setState({ tokenCredentials: tokenCredentials });
    };

    onSubmit() {
        let sendData = {
            title: this.state.tokenCredentials.title,
            type: this.state.tokenCredentials.type,
            duration: this.state.tokenCredentials.duration,
            app_id: this.state.tokenCredentials.app_id,
        };
        if (!globalState.getLocalStorage('sub_user_authenticated')) {
            sendData['domain'] = this.state.tokenCredentials.domain;
        }
        requests.postRequest('core/token/create', sendData).then(e => {
            if (e.success) {
                this.toggleToast(e.message);
                setTimeout(() => {
                    this.redirect('/panel/token');
                }, 1000)
            } else {
                this.toggleToast(e.message, true);
            }
        })
    }

    toggleToast(message, error) {
        this.setState({
            showToast: !this.state.showToast,
            showToastError: error,
            message: message,
        });
    }


    render() {
        const { showToast, apps } = this.state;
        const toastMarkup = showToast ? (
            <Toast content={this.state.message} error={this.state.showToastError} onDismiss={this.toggleToast} />
        ) : null;
        return (
            <Page title={this.state.title} primaryAction={{ content: 'Back', onClick: () => { this.redirect('/panel/token') } }}>
                {toastMarkup}
                <Card sectioned>
                    <FormLayout>
                        <TextField
                            label={"Title"}
                            value={this.state.tokenCredentials.title}
                            readOnly={false}
                            onChange={this.handleChange.bind(this, 'title')}
                        />
                        {globalState.getLocalStorage('sub_user_authenticated') ? null : <TextField
                            label={"Domain"}
                            value={this.state.tokenCredentials.domain}
                            readOnly={false}
                            onChange={this.handleChange.bind(this, 'domain')}
                        />}
                        <TextField
                            onChange={this.handleChange.bind(this, 'duration')}
                            value={this.state.tokenCredentials.duration}
                            type="number"
                            label={"Duration"} readOnly={false} />
                        <Select
                            onChange={this.handleChange.bind(this, 'type')}
                            value={this.state.tokenCredentials.type}
                            label="Type" options={[
                                { label: 'Refresh Token', value: 'refresh' },
                                { label: 'Api Token', value: 'customer_api' },
                            ]} />
                        <Select
                            onChange={this.handleChange.bind(this, 'app_id')}
                            placeholder="choose the App"
                            value={this.state.tokenCredentials.app_id}
                            label="Type" options={apps} />
                        <Stack distribution="trailing">
                            <Button onClick={() => { this.redirect('/panel/token') }}>
                                cancel
                            </Button>
                            <Button primary onClick={this.onSubmit}>
                                Submit
                            </Button>
                        </Stack>
                    </FormLayout>
                </Card>
            </Page>
        );
    }
    redirect(url) {
        this.props.history.push(url);
    }
}

export default CreateEditToken;