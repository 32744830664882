import React from 'react'
import { Checkbox } from '@shopify/polaris';
export default function Checkbox1(props) {
    return (
        <div>
            <Checkbox id={props.name1}
                label={props.name}
                checked={props.initial}
                onChange={(e, id) => props.change(id)}
            />
        </div>
    )
}