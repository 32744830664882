import React, {Component} from 'react';
import {requests} from "../../../services/request";
import {Toast} from "@shopify/polaris";

class VerificationToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showToast: false,
            showToastError: false,
            message: '',
        };
    }

    componentDidMount() {

        if ( this.props.match.params !== undefined && this.props.match.params['verificationToken'] !== undefined ) {
            requests.postRequest('user/verifyuser?token' + this.props.match.params['verificationToken']).then(e => {
                this.toggleToast(e.message, !e.success);
                setTimeout(() => {
                    this.props.history.push('/auth');
                },1000);
            })
        } else {
            this.toggleToast('Wrong Param Passed.', true);
            setTimeout(() => {
                this.props.history.push('/auth');
            },1000);
        }

    }

    toggleToast = (message, error = false) => {
        this.setState({
            showToast:!this.state.showToast,
            showToastError: error,
            message: message,
        });
    };

    render() {
        const {showToast} = this.state;
        const toastMarkup = showToast ? (
            <Toast content={this.state.message} error={this.state.showToastError} onDismiss={this.toggleToast} />
        ) : null;
        return (
            <div>
                {toastMarkup}
            </div>
        );
    }
}

export default VerificationToken;